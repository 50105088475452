import { navigate } from 'gatsby';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import BaseModal from './BaseModal';
import firebase from 'gatsby-plugin-firebase';
import { ToastContainer, toast } from 'react-toastify';

import DatabaseContext from '../contexts/DatabaseContext';
import initialState from '../data/initialState.json';
import { v4 as uuidv4 } from 'uuid';

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingAnonymous, setLoadingAnonymous] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const {
    user,
    loginWithGoogle,
    loginAnonymously,
    logout,
    setUser,
  } = useContext(UserContext);

  const [pistisUser, setPistisUser] = useState('');
  const [pistisPassword, setPistisPassword] = useState('');
  const { updateResume } = useContext(DatabaseContext);

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };

  const handleSignInAnonymously = async () => {
    setLoadingAnonymous(true);
    await loginAnonymously();
    setLoadingAnonymous(false);
  };

  const handleGotoApp = () => {
    navigate('/app/dashboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || 'Agent 47' })
      : t('modals.auth.whoAreYou');

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  const loggedInAction = (
    <>
      <Button outline className="mr-8" onClick={logout}>
        {t('shared.buttons.logout')}
      </Button>
      <Button title="" onClick={handleGotoApp}>
        {t('landing.hero.goToApp')}
      </Button>
    </>
  );

  function handleSignInPistis() {
    console.log(pistisUser, pistisPassword);
    let formdata = new FormData();
    formdata.append('email', pistisUser);
    formdata.append('password', pistisPassword);

    fetch('https://pistis.io/authenticate', {
      method: 'POST',

      body: formdata,
    })
      .then((r) => {
        return r.json();
      })
      .then((d) => {
        let response = d;
        if (d.success == true) {
          console.log(d);
          let firstN, lastN;
          window.localStorage.setItem('user_data', JSON.stringify(d.user));

          let name = d.user.name.admin_name.split(' ');
          console.log(name, '???????????????????????');
          initialState.profile.firstName = name[0];
          initialState.profile.lastName = name[1];
          initialState.profile.photograph = `https://pistis.io/${d.user.email}.jpg`;
          initialState.profile.email = d.user.email;
          initialState.profile.subtitle = d.user.desc;
          initialState.profile.website = d.user.web;
          initialState.profile.address.line1 = d.user.city + ' ' + d.user.state;
          initialState.profile.address.city = d.user.city;
          console.log('form authmodal', initialState.profile);
          if (
            d.user.linkedin != '' &&
            !initialState.social.items.some((e) => e.network === 'linkedin')
          ) {
            let item1 = {
              id: uuidv4(),
              network: 'linkedin',
              username: d.user.name.admin_name,
              url: d.user.linkedin,
            };
            initialState.social.items.push(item1);
          }
          if (
            d.user.facebook != '' &&
            !initialState.social.items.some((e) => e.network === 'facebook')
          ) {
            let item2 = {
              id: uuidv4(),
              network: 'facebook',
              username: d.user.name.admin_name,
              url: d.user.facebook,
            };
            initialState.social.items.push(item2);
          }

          console.log(initialState.social);

          toast.success('Aunthentication Successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // localStorage.setItem('user',pistisUser)
          // setUser(pistisUser)
          firebase
            .auth()
            .createUserWithEmailAndPassword(pistisUser, pistisPassword)
            .then((r) => {})
            .catch((e) => {
              if (e.code == 'auth/email-already-in-use') {
                firebase
                  .auth()
                  .signInWithEmailAndPassword(pistisUser, pistisPassword)
                  .then((r) => {
                    localStorage.setItem('user', pistisUser);
                    setUser(pistisUser);
                    navigate('/app/dashboard');
                  })
                  .catch((e) => {
                    alert('Invalid User Credentials');
                  });
              }
            });
        } else if (d.msg == 'User not found') {
          // alert(response.msg)
          // toast('')
          toast.error('Account does not exist.Please Signup to continue', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (d.msg == 'Wrong password') {
          toast.error('Invalid Credentials', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // alert(response.msg)
        }
      });
    // response=response.json()
    // console.log(response.success);
  }

  const loggedOutAction = (
    <div style={{ width: '100%' }}>
      {/* <Button isLoading={isLoadingGoogle} onClick={handleSignInWithGoogle}>
        {t('modals.auth.buttons.google')}
      </Button>
      <Button
        className="ml-8"
        isLoading={isLoadingAnonymous}
        onClick={handleSignInAnonymously}
      >
        {t('modals.auth.buttons.anonymous')}
      </Button> */}
      <div>
        <ToastContainer />
        <h1 className="font-extrabold text-3xl" style={{ textAlign: 'center' }}>
          Login to Pistis.io vResume Portal
        </h1>
        <br />
        <div
          style={{ height: 'auto', width: '100%' }}
          className="bg-white   flex flex-col align-middle"
        >
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2 text-xl"
              for="username"
            >
              Pistis.io Email ID:
            </label>
            <input
              value={pistisUser}
              onChange={(e) => {
                setPistisUser(e.target.value);
              }}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="username"
              type="text"
              placeholder="Email Id"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-2 text-xl"
              for="password"
            >
              Pistis.io Password:
            </label>
            <input
              value={pistisPassword}
              onChange={(e) => {
                setPistisPassword(e.target.value);
              }}
              className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
              id="password"
              type="password"
              placeholder="******************"
            />
          </div>
          <div className="flex items-center justify-end">
            {/* <button className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button">
              Sign In
      </button> */}
            <a
              className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
              href="https://pistis.io/#/forgot"
            >
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        <Button
          className=""
          isLoading={isLoadingAnonymous}
          onClick={handleSignInPistis}
        >
          Login
        </Button>
        <a href="https://pistis.io/#/signup" target="_blank">
          <Button isLoading={isLoadingAnonymous}>Sign Up</Button>
        </a>
      </div>
    </div>
  );

  return (
    <BaseModal
      state={[open, setOpen]}
      action={user ? loggedInAction : loggedOutAction}
    >
      {/* <p className="leading-loose">{getMessage()}</p> */}
    </BaseModal>
  );
};

export default memo(AuthModal);
